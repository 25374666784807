<template>
  <div class="card card-body shadow-sm">
    <validation-observer
      ref="form_create_entry"
      v-slot="{ passes, valid, validated }"
    >
    <app-basic-table
      ref="basic_table"
      :table-name="$t('users.user list')"
      :filters.sync="filters"
      :setting-columns="columns"
      :endpoint-list="ENDPOINT.RESTAURANT_LIST"
      :before-fetch="ENDPOINT.UI_HELPER_RESTAURANTS_SMAREGI"
      :params-before="{status: 'all'}"
      @resetAllSearch="onResetAllSearch"
      @searched="onSearch"
      @beforeFetch="beforeFetch"
    >
      <template v-slot:filters>
        <div class="row mt-3">
          <label class="align-self-center mb-0 mr-5 pl-2">{{ filters.status_equal.label }}</label>
          <div class="col-xl-5 col-lg-5 col-sm-5 gr-icheck mt-0">
            <app-radio v-for="(item, index) in RESTAURANTS_CONSTANT.FILTER_STATUS"
                            :isMarginLeft="index !=0 ? true : false" :key="item.id" :label="item.name"
                            :val="item.id" v-model="filters.status_equal.value"/>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-sm-12"></br></div>
        </div>
        <div class="row">
          <div class="col-xl-4 col-lg-4 col-sm-4">
            <div class="form-group">
              <label>{{ filters.apartment_id_equal.label }}</label>
              <app-select :name="filters.apartment_id_equal.name"
                          input-style="normal"
                          v-model="filters.apartment_id_equal.value"
                          :options-data="meta.apartments"
              />
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-sm-4">
            <div class="form-group">
              <label>{{ filters.facility_id_equal.label }}</label>
              <app-select :name="filters.facility_id_equal.name"
                          input-style="normal"
                          v-model="filters.facility_id_equal.value"
                          :options-data="meta.facilities"
              />
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-sm-4">
            <div class="form-group">
              <label>{{ filters.name_equal.label }}</label>
              <app-input :name="filters.name_equal.name" input-style="normal"
                         v-model="filters.name_equal.value"
              />
            </div>
          </div>
        </div>

      </template>


      <template v-slot:body-cell-apartment_id="props">
        <td class="app-align-middle">
          <p :data-original-title="props.row.apartment_name" style="color: #007bff;"
             @click="handlerApartmentNameClick(props.row)" class="apartment_name app-table-p app-cell-tooltip mb-0">
            {{props.row.apartment_name}}
          </p>
        </td>
      </template>

      <template v-slot:body-cell-facility_id="props">
        <td class="app-align-middle">
          <p :data-original-title="props.row.facility_name" style="color: #007bff;"
             @click="handlerFacilityNameClick(props.row)" class="facility_name app-table-p app-cell-tooltip mb-0">
            {{props.row.facility_name}}
          </p>
        </td>
      </template>

      <template v-slot:body-cell-smaregi_store_id="props">
        <td class="app-align-middle" v-bind:style="'background-color :' + getColorDataSmaregiStoreId(props.row.smaregi_store_id)">
          <p class="app-table-p app-cell-tooltip mb-0 text-center">
            {{ getDataSmaregiStoreId(props.row.smaregi_store_id) }}
          </p>
        </td>
      </template>

      <template v-slot:body-cell-status_enabled="props">
        <td class="app-align-middle">
          <p class="app-table-p app-cell-tooltip mb-0" style="align-items: center;align-self: center;display: inline;position: relative;top: 10px;">
            {{getStatusEnabled(props.row.status_enabled)}}
          </p>
          <button
            @click="handleOpenQRCode(props.row)"
            class="btn btn-primary"
            style="float: right"
          >
            QR表示
          </button>
        </td>
      </template>

      <template v-slot:table-menu-right>
        <button @click="handleBtnCreateClick()" class="btn btn-warning">
          {{ $t("common.create") }}
        </button>
      </template>

      <template v-slot:body-cell-edit="props">
        <td class="app-align-middle text-center app-p-none">
          <button
            @click="handleBtnUpdateClick(props.row)"
            class="btn btn-primary"
          >
            {{ $t("common.edit") }}
          </button>
        </td>
      </template>


    </app-basic-table>
    </validation-observer>

    <!--    <create-component-->
    <!--      ref="modal_create"-->
    <!--      @onSuccess="handleCreateSuccess"-->
    <!--    ></create-component>-->

    <!--    <update-component-->
    <!--      @onSuccess="handleUpdateSuccess"-->
    <!--      ref="modal_update"-->
    <!--    ></update-component>-->

  </div>
</template>

<script>
  import AppBasicTable from "@components/_common/list/AppBasicTable";
  import {BASE_URL, ENDPOINT} from "../../../constants/api";
  import {FACILITIES_CONSTANT} from "../../../constants/facilities";
  import {COLOR_STATUS_TABLE_NG, COLOR_STATUS_TABLE_OK, RESTAURANTS_CONSTANT} from "../../../constants/restaurants";

  export default {
    name: "RestaurantList",
    components: {
      "app-basic-table": AppBasicTable,
    },
    data() {
      return {
        idCache: null,
        paramGetList: {},
        filters: {
          apartment_id_equal: {
            name: "apartment_id",
            condition: "equal",
            label: this.$t("common.mansion")
          },
          facility_id_equal: {
            name: "facility_id",
            condition: "equal",
            label: this.$t("book-masters.filter_facility")
          },
          name_equal: {
            name: "name_like",
            condition: "like",
            label: this.$t("restaurants.name")
          },
          status_equal: {
            name: "status_enabled",
            condition: "equal",
            label: this.$t("restaurants.status_enabled")
          },
        },
        columns: [
          {name: "id", label: this.$t("common.id"), textAlign: 'text-center'},
          {name: "name", label: this.$t("restaurants.name_table")},
          {name: "apartment_id", label: this.$t("restaurants.mansion"), textAlign: 'text-center'},
          {name: "facility_id", label: this.$t("restaurants.facility"), textAlign: 'text-center'},
          {name: "smaregi_store_id", label: this.$t("restaurants.smaregi_store_id_list"), textAlign: 'text-center'},
          {name: "status_enabled", label: this.$t("restaurants.status_enabled"), textAlign: 'text-center'},
          {name: "edit", label: this.$t("common.edit"), textAlign: 'text-center'},

        ],
        meta: {},
        smaregiStores: [],
      };
    },
    watch: {
      'filters.apartment_id_equal.value': function(value, oldValue) {
        this.getParamsFilters({apartment_id : value})
      },
    },
    methods: {
      beforeFetch(res) {
        this.smaregiStores = res.data.smaregiStores;
      },
      getParamsFilters(params) {
        this.$request.get(this.ENDPOINT.UI_HELPER_FACILITIES_BIZ_TYPE(params.apartment_id, 0)).then(res => {
          this.meta = {
            apartments: res.data.apartments,
            facilities: res.data.facilities,
            facilities_all : res.data.facility_all
          }
          this.meta.apartments.unshift({
            id: "all",
            name: ""
          })
          this.meta.facilities.unshift({
            id: "all",
            name: ""
          })
          this.meta.facilities_all.unshift({
            id: "all",
            name: ""
          })
          let vm = this
          if (!this.meta.facilities.find(({id}) => id === parseInt(vm.filters.facility_id_equal.value))) {
            this.filters.facility_id_equal.value = 'all';
          }
          this.$forceUpdate();
        })
      },
      getFacilityList(apartment_id) {
        this.$request.get(this.ENDPOINT.UI_HELPER_BOOK_MASTERS_FACILITY_LIST(apartment_id)).then(res => {
          let  facilities = res.data.facilities;
          facilities.unshift({
            id: 'all',
            name: this.$t('common.all'),
          });
          if (apartment_id != 'all') {
            this.meta.facilities = facilities;
          } else {
            this.meta.facilities = [{
              id: 'all',
              name: this.$t('common.all'),
            }];
          }

        })
      },
      handleBtnCreateClick() {
        this.$router.push({name: this.ROUTES.ADMIN.RESTAURANTS_CREATE})
      },

      handleBtnUpdateClick(entry) {
        this.$router.push({name: this.ROUTES.ADMIN.RESTAURANTS_EDIT, params: {id: entry.id}})
      },
      async handleOpenQRCode(entry) {
        window.open( `/restaurants/qr_code_table/${entry.id}`, '_blank')
      },
      handleBtnManageStatus(entry) {
        this.$router.push({name: this.ROUTES.ADMIN.FACILITY_MANAGE_STATUS, params: {id: entry.id}})
      },

      handlerApartmentNameClick(entry) {
        let routeApartment = this.$router.resolve({name: this.ROUTES.ADMIN.APARTMENT_EDIT, params: {id: entry.apartment_id}});
        window.open(routeApartment.href, '_blank');
      },

      handlerFacilityNameClick(entry) {
        let routeFacility = this.$router.resolve({name: this.ROUTES.ADMIN.FACILITY_EDIT, params: {id: entry.facility_id}});
        window.open(routeFacility.href, '_blank');
      },

      getStatusEnabled(status) {
        let data = RESTAURANTS_CONSTANT.STATUS.find(({id}) => id === status);
        return data.name
      },
      getDataSmaregiStoreId(smaregiStoreId) {
        let statusStore = this.smaregiStores.find(({id}) => id === smaregiStoreId)
        if (statusStore !== undefined && statusStore.suspend == null) {
          return smaregiStoreId + ' (OK)'
        }
        return smaregiStoreId + ' (NG)'
      },
      getColorDataSmaregiStoreId(smaregiStoreId) {
        let statusStore = this.smaregiStores.find(({id}) => id === smaregiStoreId)
        if (statusStore !== undefined && statusStore.suspend == null) {
          return COLOR_STATUS_TABLE_OK
        }
        return COLOR_STATUS_TABLE_NG
      },
      onSearch() {

      },
      onResetAllSearch() {
        this.$router.push({
          name: this.$route.name,
          query: {
            'filters.status_enabled.equal': 'all',
            'filters.apartment_id.equal' : 'all',
            'filters.facility_id.equal' : 'all'
          }
        }).catch(e => {
          console.log('no warning console');
        })
      },
    },
  }
</script>
